<script setup lang="ts">
import { useCollapse } from '../composables/collapse'

const { isOpen, isMobileOpen, toggle } = useCollapse()
const { xl } = useTailwindBreakpoints()
</script>

<template>
  <button
    v-if="!xl"
    type="button"
    class="flex h-10 w-10 items-center justify-center"
    @click="() => toggle()"
  >
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_8015_44410)">
        <path
          d="M0 0.304688H20V2.72893H0V0.304688ZM0 8.78954H13.3333V11.2138H0V8.78954ZM0 17.2744H20V19.6986H0V17.2744Z"
          fill="#F18B21"
        />
      </g>
      <defs>
        <clipPath id="clip0_8015_44410">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </button>
</template>
