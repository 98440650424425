<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useAuthStore } from '~/store/auth.js'
const props = defineProps<{
  item: any
  expanded?: boolean
}>()
const emit = defineEmits(['clicked'])
const { isMobileOpen } = useCollapse()
const route = useRoute()

const store = useAuthStore()
const { isExternalUser } = storeToRefs(store)

const isActive = computed(() => {
  if (!props.item?.activePath) {
    return false
  }

  return route.path.startsWith(props.item.activePath)
})

const buttonRef = ref<HTMLElement>()
const isOpen = ref(isActive.value)

function onDropClick() {
  isOpen.value = !isOpen.value
  if (!isOpen.value) {
    buttonRef.value?.blur()
  }
  emit('clicked')
}
</script>

<template>
  <div class="group">
    <button
      class="nui-focus flex w-full items-center rounded-lg py-3 text-[#475569] transition-colors duration-300 focus-within:outline-none dark:text-[#CBD5E1] dark:focus-within:outline-none"
      :class="[
        props.expanded ? 'gap-5 px-4' : 'justify-center px-2',
        props.item.name === 'Claims'
          ? 'cursor-auto hover:text-[#475569] dark:hover:text-[#CBD5E1] '
          : 'hover:text-primary-500 dark:hover:text-primary-500 cursor-pointer',
      ]"
      ref="buttonRef"
      @click.stop.prevent="onDropClick"
    >
      <Icon
        :name="item.icon.name"
        :class="[item.icon.class, isActive && 'text-primary-500']"
        class="shrink-0"
      />
      <span
        class="font-heading block whitespace-nowrap text-sm"
        :class="[
          isActive && 'text-primary-500',
          !props.expanded ? 'hidden' : 'block',
        ]"
      >
        {{ item.name }}
      </span>
      <span
        v-if="!isExternalUser && props.item.name !== 'Claims'"
        class="ms-auto items-center justify-center"
        :class="!props.expanded ? 'hidden' : 'flex'"
      >
        <Icon
          name="material-symbols:keyboard-arrow-down"
          class="h-[25px] w-[25px] text-[#475569] transition-transform duration-200 dark:text-[#CBD5E1]"
          :class="!isOpen ? 'rotate-180' : ''"
        />
      </span>
    </button>

    <ul
      v-if="props.expanded"
      class="border-muted-200 relative block ps-4"
      :class="{
        'max-h-0 overflow-hidden opacity-0 group-focus-within:max-h-max group-focus-within:overflow-visible group-focus-within:opacity-100':
          !isOpen && props.item.name !== 'Claims',
        'after:border-muted-200 max-h-max opacity-100':
          isOpen && props.item.name === 'Claims',
      }"
    >
      <li
        v-for="child in props.item.children"
        :key="child.to"
        class="border-muted-300 dark:border-muted-700 hover:!border-primary-500 ms-2 border-s-2 first:mt-2"
      >
        <NuxtLink
          :to="child.to"
          exact-active-class="!border-primary-500 !text-primary-500 dark:!text-primary-500"
          :class="
            (route.path.startsWith('/insurance-companies/') &&
              child.name === 'Insurance Companies') ||
            `/board/${route.params.id}` === child.to
              ? '!text-primary-500'
              : ''
          "
          class="nui-focus ps-7.5 hover:text-primary-500 dark:hover:text-primary-500 relative -left-0.5 flex cursor-pointer items-center gap-2.5 border-s-2 border-transparent py-2 font-medium text-[#475569] transition-colors duration-300 focus-within:outline-none dark:text-[#CBD5E1] dark:focus-within:outline-none"
          @click="isMobileOpen = false"
        >
          <Icon
            :name="child.icon.name"
            :class="child.icon.class"
            class="shrink-0"
          />
          <span
            class="font-heading navigation-links truncate whitespace-nowrap text-sm"
            :class="[!props.expanded ? 'hidden' : 'block']"
          >
            {{
              child.name
                .toLowerCase()
                .replace(/\b(\w)/g, (name) => name.toUpperCase())
            }}
          </span>
        </NuxtLink>
      </li>
    </ul>
  </div>
</template>
